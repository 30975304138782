























































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Store
import { documentsNamespace } from '@store/documents'

// Interfaces
import { Team } from '@store/team/interfaces'
import { IDocumentTypes } from '@store/documents/Interface'
import { TeamActions } from '@/store/team/types'
import { RequestParams } from '@/store/interfaces'

// Modules
const NSTeam = namespace('teamModule')

@Component({
  name: 'TeamMember',
})
export default class TeamMemberComponent extends Vue {
  @Prop(Object) private teamMember!: Team

  @documentsNamespace.State('documentTypes')
  private documentTypes!: IDocumentTypes[]

  @NSTeam.Action(TeamActions.A_DISABLE_SPECIALIST)
  private disableSpecialist!: (id: number) => Promise<void>

  private visibleDialogInfo = false

  private get shortName(): string {
    return `${this.teamMember.surname} ${this.teamMember.name[0]}.`
  }

  private get documentType(): string {
    if (this.teamMember.documentTypeId) {
      const [type] = this.documentTypes.filter(t => t.id === this.teamMember.documentTypeId)

      return type.name
    } else {
      return ''
    }
  }

  private confirmDisabled(): void {
    this.$confirm('Вы действительно хотите отключить специалиста?', 'Внимание!', {
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
      type: 'warning',
    }).then(() => {
      this.disableSpecialist(this.teamMember.id)
        .then(() => {
          this.$notify({
            title: 'Выполнено',
            message: 'Действие успешно выполнено',
            type: 'success',
          })
        })
        .catch(error => {
          this.$notify.error({
            title: 'Ошибка',
            message: error.response.data.message,
          })
        })
    })
  }
}
